<template>
  <div class="about">
    <div>
        <el-input size="mini" style="width: 300px;margin: 0 10px 0 0"  v-model="keywords"></el-input>
        <el-select clearable style="margin:0 15px" v-model="area_id" @clear="clearOption">
                <el-option
                    v-for="item1 in region"
                    :key="item1.id"
                    :label="item1.area_name"
                    :value="item1.area_id"
                    >
                </el-option>
              </el-select>
        <el-select v-model="area_type" class="m-2" placeholder="地区筛选" size="mini" v-if="is_show">
            <el-option
                v-for="item in lnTypeList"
                :key="item.value"
                :label="item.label"
                :value="item.label"
            >
            </el-option>
        </el-select>
        <el-button size="mini" @click="search()" type="primary" style="margin:10px"  icon="el-icon-search"></el-button>
        <el-button size="mini" @click="onDownload()" type="primary"   icon="el-icon-download" >Excel 导出</el-button>
    </div>
    <el-button  style="margin-bottom: 10px" size="mini" v-for="(item,i) in SearchStatusArr" :key="i" :class="item.status==status?'onserch':''" type="primary" plain @click="onOrderSerch(item.status)">{{item.title}} <span v-if="item.count>0" style="color: red">({{item.count}})</span></el-button>
    <el-table  height="calc(100vh - 280px)" :data="tableData" style="width: 100%;">
      <el-table-column width="55" prop="id" label="#" >
      </el-table-column>
      <el-table-column prop="product_logo"  width="80" label="产品图" >
      <template #default="scope">
        <div style="height: 100%;display: flex;align-items:center;">
          <el-image
              v-if="scope.row.product_logo"
              style="width: 50px; height: 50px"
              :src="scope.row.product_logo"
              :preview-src-list="[scope.row.product_logo]">
          </el-image>
        </div>
      </template>
      </el-table-column>
      <el-table-column width="204" prop="order_no" label="订单编号" >
      </el-table-column>
      <el-table-column width="115" prop="product_name" label="产品名称" >
      </el-table-column>
      <el-table-column width="100" prop="status" label="订单状态" >
        <template #default="scope">
          <div v-if="scope.row.status==0">
            新订单
          </div>
          <div v-else-if="scope.row.status==1">
            抢单报价中
          </div>
          <div v-else-if="scope.row.status==2">
            等待服务
          </div>
          <div v-else-if="scope.row.status==3">
            等待验收
          </div>
          <div v-else-if="scope.row.status==4">
            服务结束
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="pay_item" label="支付状态" >
        <template #default="scope">
          <div v-if="scope.row.pay_status==0">
            <el-tag size="mini" type="info">待支付</el-tag>
          </div>
          <div v-else-if="scope.row.pay_status==1">
            <el-tag size="mini">支付中</el-tag>
          </div>
          <div v-else-if="scope.row.pay_status==2">
            <el-tag size="mini" effect="dark" type="success">已支付</el-tag>
          </div>
          <div v-else-if="scope.row.pay_status==3">
            <el-tag size="mini" type="danger">支付失败</el-tag>
          </div>
        </template>
      </el-table-column>
      <el-table-column width="110" prop="pay_item" label="客户维度状态" >
        <template #default="scope">
          <div v-if="scope.row.member_status==0">
            待付款
          </div>
          <div v-else-if="scope.row.member_status==1">
            待接单
          </div>
          <div v-else-if="scope.row.member_status==2">
            待服务
          </div>
          <div v-else-if="scope.row.member_status==3">
            待验收
          </div>
          <div v-else-if="scope.row.member_status==4">
            已完成
          </div>
          <div v-else-if="scope.row.member_status==5">
            已关闭
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="pay_item" label="派单类型" >
        <template #default="scope">
          <div v-if="scope.row.product_grab_type==0">
            师傅报价
          </div>
          <div v-else-if="scope.row.product_grab_type==1">
            平台指派
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="product_mode_name" label="下单模式" >
      </el-table-column>
      <el-table-column prop="pay_item" label="支付模式" >
        <template #default="scope">
          <div v-if="scope.row.product_pay_type==0">
            预付
          </div>
          <div v-else-if="scope.row.product_pay_type==1">
            现付
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="member_name" label="已派单" >
        <template #default="scope">
          <div style="font-size: 25px;font-weight: 600" v-if="scope.row.is_delivery==1">
            √
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="remark" width="160" label="备注" show-overflow-tooltip >
      </el-table-column>
      <el-table-column width="70" prop="member_id" label="客户ID" >
      </el-table-column>
      <el-table-column prop="member_name" label="客户姓名" >
      </el-table-column>
      <el-table-column width="127" prop="member_mobile_phone" label="客户电话" >
      </el-table-column>
      <el-table-column prop="pay_item" label="支付项" >
        <template #default="scope">
          <div v-if="scope.row.pay_item==0">
            无
          </div>
          <div v-else-if="scope.row.pay_item==1">
            预付款
          </div>
          <div v-else-if="scope.row.pay_item==2">
            尾款
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="total_amount" label="订单总额" >
        <template #default="scope">
          ￥{{scope.row.total_amount}}
        </template>
      </el-table-column>
      <el-table-column prop="coupon_amount" label="优惠券抵扣金额" >
        <template #default="scope">
          ￥{{scope.row.coupon_amount}}
        </template>
      </el-table-column>
      <el-table-column prop="paid_amount" label="已付金额" >
        <template #default="scope">
          ￥{{scope.row.paid_amount}}
        </template>
      </el-table-column>
      <el-table-column prop="unpaid_amount" label="未付金额" >
        <template #default="scope">
          ￥{{scope.row.unpaid_amount}}
        </template>
      </el-table-column>
      <el-table-column prop="amount" label="订单实际金额" >
        <template #default="scope">
          ￥{{scope.row.unpaid_amount}}
        </template>
      </el-table-column>
      <el-table-column prop="basic_fee_amount" label="上门服务费金额" >
        <template #default="scope">
          ￥{{scope.row.basic_fee_amount}}
        </template>
      </el-table-column>
      <el-table-column prop="deduction_amount" label="扣费总额" >
        <template #default="scope">
          ￥{{scope.row.deduction_amount}}
        </template>
      </el-table-column>
      <el-table-column prop="product_grab_type_name" label="下单抢单类型" >
      </el-table-column>
      <el-table-column prop="flow_status" label="订单处理流程状态" >
      </el-table-column>

      <el-table-column prop="close_offer_time" width="162" label="截止报价时间" >
      </el-table-column>
      <el-table-column prop="is_refundable" label="可退服务费" >
        <template #default="scope">
          <div style="font-size: 25px;font-weight: 600" v-if="scope.row.is_refundable==0">
            ×
          </div>
          <div style="font-size: 25px;font-weight: 600" v-if="scope.row.is_refundable==1">
            √
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="is_refund" label="申请退款" >
        <template #default="scope">
          <div v-if="scope.row.is_refund==0">
            无
          </div>
          <div  v-if="scope.row.is_refund==1">
            已退款
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="confirm_offer_price" label="确认报价" >
        <template #default="scope">
            <div v-if="scope.row.confirm_offer_price==0">
              无
            </div>
            <div  v-if="scope.row.confirm_offer_price==1">
              待确认
            </div>
        </template>
      </el-table-column>
      <el-table-column width="305" prop="expected_start_time" label="预期时间" >
        <template #default="scope">
          {{scope.row.expected_start_time}}-{{scope.row.expected_end_time}}
        </template>
      </el-table-column>
      <el-table-column width="160" prop="create_time" label="下单时间" >
      </el-table-column>
      <el-table-column width="160" prop="pay_time" label="支付时间" >
      </el-table-column>
      <el-table-column width="160" prop="call_time" label="师傅预约时间" >
      </el-table-column>
      <el-table-column width="160" prop="finish_time" label="结束时间" >
      </el-table-column>
      <el-table-column fixed="right" prop="address" label="操作" width="164">
<!--      <el-table-column fixed="right" prop="address" label="操作" width="240">-->
        <template #default="scope">
          <div style="margin: 5px 0">
            <el-button-group>
              <el-button size="mini"  type="primary" @click="onOrder(scope.row)">详情</el-button>
              <el-button size="mini" v-if="scope.row.is_AssignWorker==1" type="primary" @click="upData(scope.row,'改派')">改派师傅</el-button>
              <el-button size="mini" v-else :disabled="scope.row.is_delivery==1||scope.row.member_status==5||scope.row.member_status==3||scope.row.member_status==4||scope.row.is_cancel==1||(scope.row.product_grab_type==0&&scope.row.pay_status==2)" type="primary" @click="upData(scope.row)">指派师傅</el-button>
<!--              <el-button size="mini" :disabled="scope.row.member_status!=1||scope.row.pay_item!=0" type="primary" @click="upData(scope.row)">指派师傅</el-button>-->
<!--              <el-button size="mini" :disabled="scope.row.pay_status!=2" type="primary"  @click="onassignWorker(scope.row)">申请退款</el-button>-->
            </el-button-group>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div style="margin-top: 20px">
      <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="page"
          :page-sizes="[10, 20, 30, 40,50]"
          :page-size="limit"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
      </el-pagination>
    </div>
    <el-drawer
        title="指派师傅"
        v-model="drawer"
        size="50%"
        direction="rtl"
        :close-on-click-modal="false"
        destroy-on-close>
      <div>
        <el-descriptions border size="mini" :title="this.rowData.product_name">
          <el-descriptions-item label="产品图">
            <div style="height: 100%;display: flex;align-items:center;padding: 5px 0">
              <el-image
                  v-if="this.rowData.product_logo"
                  style="width: 40px; height: 40px"
                  :src="this.rowData.product_logo"
                  :preview-src-list="[this.rowData.product_logo]">
              </el-image>
            </div>
          </el-descriptions-item>
          <el-descriptions-item label="订单编号">{{this.rowData.order_no}}</el-descriptions-item>
          <el-descriptions-item label="客户地址:">{{this.rowData.member_address}}</el-descriptions-item>
        </el-descriptions>
      </div>
      <div>
        <el-cascader
            placeholder="选择部门"
            clearable
            size="mini"
            ref="cascaderHandle"
            style="width: 200px;margin-right: 10px"
            :options="areaArr"
            v-model="group_id"
            :props="{
                      label: 'name',
                      value: 'id',
                      children: 'children',
                      checkStrictly: true
                      }"
            :filterable="true">
        </el-cascader>
        <el-input size="mini" style="width: 200px;margin: 0 10px 0 0"  v-model="Offerkeywords"></el-input>
        <el-button size="mini" @click="Offersearch()" type="primary" style="margin:10px 0;" icon="el-icon-search"></el-button>
      </div>
      <el-table border height="calc(100vh - 335px)" :data="OffertableData" style="width: 100%;">
        <el-table-column  align="center"
                          type="index"
                          width="55" label="#">
        </el-table-column>
        <el-table-column prop="nickname" label="师傅名称">
        </el-table-column>
        <el-table-column prop="group_name" show-overflow-tooltip width="90" label="部门">
          <template #default="scope">
            <div v-if="scope.row.group_name">
              <el-tag type="success" size="mini">{{ scope.row.group_name }}</el-tag>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="mobile_phone" label="电话" >
        </el-table-column>
        <el-table-column prop="real_name" label="真实姓名" >
        </el-table-column>
        <el-table-column width="100" prop="is_of_us" label="是否直选" >
          <template #default="scope">
            <div v-if="scope.row.is_of_us==1">
              <el-tag size="mini">直选师傅</el-tag>
            </div>
          </template>
        </el-table-column>
        <el-table-column fixed="right" prop="address" label="操作" width="100">
          <template #default="scope">
            <div style="margin: 5px 0">
              <el-button-group>
                <el-button size="mini" v-if="rowData.is_AssignWorker==1" type="primary"  @click="offWorker(scope.row)">改派师傅</el-button>
                <el-button size="mini" v-else type="primary" @click="onWorker(scope.row)">指派师傅</el-button>
              </el-button-group>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div style="margin-top: 20px">
        <el-pagination
            background
            @size-change="OfferSizeChange"
            @current-change="OfferCurrentChange"
            :current-page="Offerpage"
            :page-sizes="[10, 20, 30, 40,50]"
            :page-size="Offerlimit"
            layout="total, sizes, prev, pager, next, jumper"
            :total="Offertotal">
        </el-pagination>
      </div>
    </el-drawer>
    <el-drawer
        title="详情"
        v-model="orderdrawer"
        size="70%"
        direction="rtl"
        :close-on-click-modal="false"
        destroy-on-close>
      <el-scrollbar style="height: 100%" max-height="calc(100vh - 120px)"  >

        <el-descriptions border :title="ruleForm.product_name">
          <el-descriptions-item label="支付状态:">
            <el-tag size="mini" type="info" v-if="ruleForm.pay_status==0">待支付</el-tag>
            <el-tag size="mini"  v-else-if="ruleForm.pay_status==1">支付中</el-tag>
            <el-tag size="mini" type="success" effect="dark" v-else-if="ruleForm.pay_status==2">已支付</el-tag>
            <el-tag size="mini" type="danger" v-else-if="ruleForm.pay_status==3">支付失败</el-tag>
          </el-descriptions-item>
          <el-descriptions-item label="订单状态:">
            <el-tag size="mini" type="info" v-if="ruleForm.member_status==0">待付款</el-tag>
            <el-tag size="mini" type="" v-else-if="ruleForm.member_status==1">待接单</el-tag>
            <el-tag size="mini" type="" v-else-if="ruleForm.member_status==2">待服务</el-tag>
            <el-tag size="mini" type="warning" v-else-if="ruleForm.member_status==3">待验收</el-tag>
            <el-tag size="mini" type="success" effect="dark" v-else-if="ruleForm.member_status==4">已完成</el-tag>
            <el-tag size="mini" type="danger" v-else-if="ruleForm.member_status==5">已关闭</el-tag>
          </el-descriptions-item>
          <el-descriptions-item label="是否派单:">
            <el-tag size="mini" type="danger" v-if="ruleForm.is_delivery==0">否</el-tag>
            <el-tag size="mini" type="success" effect="dark" v-else-if="ruleForm.is_delivery==1">是</el-tag>
          </el-descriptions-item>
          <el-descriptions-item label="是否接单:">
            <el-tag size="mini" type="danger" v-if="!ruleForm.worker_order">否</el-tag>
            <el-tag size="mini" effect="dark" v-else-if="ruleForm.worker_order">是</el-tag>
          </el-descriptions-item>
          <div v-if="ruleForm.worker_order">
            <el-descriptions-item v-if="ruleForm.worker_order.real_name" label="师傅姓名:">{{ruleForm.worker_order.real_name}}</el-descriptions-item>
            <el-descriptions-item v-else label="师傅姓名:">{{ruleForm.worker_order.nickname}}</el-descriptions-item>
            <el-descriptions-item label="师傅电话:">{{ruleForm.worker_order.mobile_phone}}</el-descriptions-item>
            <el-descriptions-item label="师傅接单状态:">
              <span v-if="ruleForm.worker_order.service_status==0">新订单</span>
              <span v-else-if="ruleForm.worker_order.service_status==1">已预约</span>
              <span v-else-if="ruleForm.worker_order.service_status==2">已上门</span>
              <span v-else-if="ruleForm.worker_order.service_status==3">待确认报价</span>
              <span v-else-if="ruleForm.worker_order.service_status==4">报价成功</span>
              <span v-else-if="ruleForm.worker_order.service_status==5">服务中</span>
              <span v-else-if="ruleForm.worker_order.service_status==6">服务结束</span>
              <span v-else-if="ruleForm.worker_order.service_status==7">已完成</span>
              <span v-else-if="ruleForm.worker_order.service_status==8">订单关闭</span>
            </el-descriptions-item>
          </div>

          <el-descriptions-item label="上门后是否可退服务费:">
            <span v-if="ruleForm.is_refundable==0">否</span>
            <span v-else-if="ruleForm.is_refundable==1">是</span>
          </el-descriptions-item>
          <el-descriptions-item label="是否发起投诉:">
            <span v-if="ruleForm.is_complain==0">否</span>
            <span v-else-if="ruleForm.is_complain==1">是</span>
          </el-descriptions-item>
          <el-descriptions-item label="投诉状态文字:">{{ruleForm.complain_status_text}}</el-descriptions-item>

          <el-descriptions-item label="发起申请退款:">
            <span v-if="ruleForm.is_refund==0">否</span>
            <span v-else-if="ruleForm.is_refund==1">是</span>
          </el-descriptions-item>
          <el-descriptions-item label="退款状态:">{{ruleForm.refund_status_text}}</el-descriptions-item>
          <el-descriptions-item label="确认报价:">
            <span v-if="ruleForm.confirm_offer_price==0">无</span>
            <span v-else-if="ruleForm.confirm_offer_price==1">待确认</span>
          </el-descriptions-item>

          <el-descriptions-item label="状态:">
            <span v-if="ruleForm.status==0">新订单</span>
            <span v-else-if="ruleForm.status==1">抢单报价中</span>
            <span v-else-if="ruleForm.status==2">等待服务</span>
            <span v-else-if="ruleForm.status==3">等待验收</span>
            <span v-else-if="ruleForm.status==4">服务结束</span>
          </el-descriptions-item>
          <el-descriptions-item label="支付项:">
            <span v-if="ruleForm.pay_item==0">无</span>
            <span v-else-if="ruleForm.pay_item==1">预付款</span>
            <span v-else-if="ruleForm.pay_item==2">尾款</span>
          </el-descriptions-item>
          <el-descriptions-item label="下单时间:">{{ruleForm.create_time}}</el-descriptions-item>

          <el-descriptions-item label="商品图">
            <div style="height: 100%;display: flex;align-items:center;padding: 5px 0">
              <el-image
                  style="width: 60px; height: 60px"
                  :src="ruleForm.product_logo"
                  :preview-src-list="[ruleForm.product_logo]">
              </el-image>
            </div>
          </el-descriptions-item>
          <el-descriptions-item label="订单编号:">{{ruleForm.order_no}}</el-descriptions-item>
          <el-descriptions-item label="产品名称:">{{ruleForm.product_name}}</el-descriptions-item>
          <!--        <el-descriptions-item label="分类:">-->
          <!--          <span v-if="ruleForm.status==0">男</span>-->
          <!--          <span v-else>女</span>-->
          <!--        </el-descriptions-item>-->
          <el-descriptions-item label="客户姓名:">{{ruleForm.member_name}}</el-descriptions-item>
          <el-descriptions-item label="客户电话:">{{ruleForm.member_mobile_phone}}</el-descriptions-item>
          <el-descriptions-item label="客户地址:">{{ruleForm.member_address}}</el-descriptions-item>

          <el-descriptions-item label="预期时间:">{{ruleForm.expected_start_time}}-{{ruleForm.expected_end_time}}</el-descriptions-item>

          <el-descriptions-item label="更新时间:">{{ruleForm.update_time}}</el-descriptions-item>
          <el-descriptions-item label="支付时间:">{{ruleForm.pay_time}}</el-descriptions-item>
          <el-descriptions-item label="师傅预约时间:">{{ruleForm.call_time}}</el-descriptions-item>

          <el-descriptions-item label="结束时间:">{{ruleForm.finish_time}}</el-descriptions-item>
          <el-descriptions-item label="订单总额:">￥ {{ruleForm.total_amount}}</el-descriptions-item>
          <el-descriptions-item label="优惠券金额:">￥ {{ruleForm.coupon_amount}}</el-descriptions-item>
          <el-descriptions-item label="退还金额:">￥ {{ruleForm.refund_amount}}</el-descriptions-item>
          <el-descriptions-item label="已付金额:">￥ {{ruleForm.paid_amount}}</el-descriptions-item>
          <el-descriptions-item label="未付金额:">￥ {{ruleForm.unpaid_amount}}</el-descriptions-item>
          <el-descriptions-item label="订单实际金额:">￥ {{ruleForm.amount}}</el-descriptions-item>
          <el-descriptions-item label="上门服务费:">￥ {{ruleForm.basic_fee_amount}}</el-descriptions-item>
          <el-descriptions-item label="扣费总额:">￥ {{ruleForm.deduction_amount}}</el-descriptions-item>

          <el-descriptions-item label="下单时抢单类型:">{{ruleForm.product_grab_type_name}}</el-descriptions-item>
          <el-descriptions-item label="下单时模式:">{{ruleForm.product_mode_name}}</el-descriptions-item>
<!--          <el-descriptions-item label="下单时派单类型:">{{ruleForm.product_grab_type}}</el-descriptions-item>-->
<!--          <el-descriptions-item label="下单时付款方式:">{{ruleForm.product_pay_type}}</el-descriptions-item>-->
<!--          <el-descriptions-item label="订单处理流程状态:">{{ruleForm.flow_status}}</el-descriptions-item>-->
          <el-descriptions-item label="备注:">{{ruleForm.remark}}</el-descriptions-item>
        </el-descriptions>


<!--
列表
-->
        <div style="margin-top: 10px;color: #606266;display: flex;justify-content: space-between"><span>● 产品属性</span> </div>
        <el-table border max-height="300px" :data="ruleForm.attribute" style="width: 100%;margin:10px 0 30px 0">
          <el-table-column prop="attr_name" label="属性名称" width="136"> </el-table-column>
          <el-table-column prop="value_name" label="属性值" width="244"> </el-table-column>
          <el-table-column prop="value_text" label="内容" min-width="244"> </el-table-column>
        </el-table>


        <div style="margin-top: 10px;color: #606266;display: flex;justify-content: space-between"><span>● 产品规格</span> </div>
        <el-table border max-height="300px" :data="ruleForm.sku_list" style="width: 100%;margin:10px 0 30px 0">
          <el-table-column prop="sku_content" label="规格名称描述" width="244"> </el-table-column>
          <el-table-column prop="amount" label="实际金额" min-width="110">
            <template #default="scope">
              ￥{{scope.row.amount}}
            </template>
          </el-table-column>
          <el-table-column prop="amount" label=" 商品价格" min-width="110">
            <template #default="scope">
              ￥{{scope.row.cost_price}}
            </template>
          </el-table-column>
          <el-table-column prop="amount" label=" 优惠券抵扣金额" min-width="110">
            <template #default="scope">
              ￥{{scope.row.coupon_amount}}
            </template>
          </el-table-column>
          <el-table-column prop="count" label="购买数量"> </el-table-column>
        </el-table>

        <div style="margin-top: 10px;color: #606266;display: flex;justify-content: space-between"><span>● 收费项目</span> </div>
        <el-table border max-height="300px" :data="ruleForm.order_amount" style="width: 100%;margin:10px 0 30px 0">
          <el-table-column prop="charge_name" label="产品收费项目" width="136"> </el-table-column>
          <el-table-column prop="charge_item_name" label="收费项目规格名" width="136"> </el-table-column>
          <el-table-column prop="amount" label="单价" min-width="110">
            <template #default="scope">
              ￥{{scope.row.price}}
            </template>
          </el-table-column>
          <el-table-column prop="num " label="数量" width="136"> </el-table-column>
          <el-table-column prop="unit" label="单位" width="136"> </el-table-column>
          <el-table-column prop="amount" label="金额" min-width="110">
            <template #default="scope">
              ￥{{scope.row.amount}}
            </template>
          </el-table-column>

        </el-table>

        <div style="margin-top: 10px;color: #606266;display: flex;justify-content: space-between"><span>● 订单图片</span> </div>
        <el-table border max-height="300px" :data="ruleForm.images" style="width: 100%;margin:10px 0 30px 0">
          <el-table-column prop="product_logo"  width="80" label="图片" >
            <template #default="scope">
              <div style="height: 100%;display: flex;align-items:center;padding: 5px 0">
                <el-image
                    v-if="scope.row.pic_url"
                    style="width: 60px; height: 60px"
                    :src="scope.row.pic_url"
                    :preview-src-list="ruleForm.images_list">
                </el-image>
              </div>
            </template>
          </el-table-column><el-table-column prop="is_display" label="图片类型" min-width="110">
            <template #default="scope">
              <span v-if="scope.row.type_id==0">下单图 </span>
              <span v-if="scope.row.type_id==1">上门签到 </span>
              <span v-if="scope.row.type_id==2">服务完成 </span>
              <span v-if="scope.row.type_id==3">退款图片 </span>
              <span v-if="scope.row.type_id==4">投诉图片 </span>
            </template>
          </el-table-column>
        </el-table>

        <div style="margin-top: 10px;color: #606266;display: flex;justify-content: space-between"><span>● 支付详情</span> </div>
        <el-table border max-height="300px" :data="ruleForm.pay_log" style="width: 100%;margin:10px 0 30px 0">
          <el-table-column prop="order_no" label="支付单号" width="205"> </el-table-column>
          <el-table-column prop="amount" label="实际支付金额" min-width="110">
            <template #default="scope">
              ￥{{scope.row.amount}}
            </template>
          </el-table-column>
          <el-table-column prop="amount" label="优惠金额" min-width="110">
            <template #default="scope">
              ￥{{scope.row.coupon_amount}}
            </template>
          </el-table-column>
          <el-table-column prop="pay_no" label="支付单号" width="136"> </el-table-column>
          <el-table-column prop="is_display" label="支付结果" min-width="110">
            <template #default="scope">
              <span v-if="scope.row.pay_status==0">无 </span>
              <span v-if="scope.row.pay_status==1">支付成功 </span>
              <span v-if="scope.row.pay_status==2">支付失败 </span>
            </template>
          </el-table-column>
          <el-table-column prop="is_display" label="支付类型" min-width="110">
            <template #default="scope">
              <span v-if="scope.row.pay_type==0">微信 </span>
              <span v-if="scope.row.pay_type==1">支付宝 </span>
            </template>
          </el-table-column>
          <el-table-column prop="is_display" label="是否退款" min-width="110">
            <template #default="scope">
              <span v-if="scope.row.is_refund==1" style="font-size: 20px"> √ </span>
            </template>
          </el-table-column>
          <el-table-column fixed="right" prop="attr_name" label="操作" width="145">
            <template #default="scope">
              <el-button type="primary" :disabled="scope.row.pay_status==1||scope.row.is_refund==1" @click="serchOrder(scope.row)" icon="el-icon-refresh" size="mini">更新支付状态</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div style="margin-top: 10px;color: #606266;display: flex;"><span>● 结算明细</span> <div v-if="ruleForm.settle_detail" ><el-button style="margin-left: 20px" type="primary" :disabled="ruleForm.settle_detail.length>0||ruleForm.pay_status!=2||ruleForm.member_status==5" @click="detail()" icon="el-icon-refresh" size="mini">生成结算明细</el-button></div> </div>
        <el-table border max-height="300px" :data="ruleForm.settle_detail" style="width: 100%;margin:10px 0 30px 0">
          <el-table-column prop="attr_name" label="类型" width="61">
            <template #default="scope">
              <el-tag size="mini" type="success" v-if="scope.row.user_type==1">用户</el-tag>
              <el-tag size="mini"  v-else-if="scope.row.user_type==2">师傅</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="account_id" label="账户ID" width="93"> </el-table-column>
          <el-table-column prop="attr_name" label="名称" width="197">
            <template #default="scope">
              <span v-if="scope.row.user_type==1&&scope.row.member_id">{{scope.row.member_name}}({{scope.row.member_id}})</span>
              <span v-if="scope.row.user_type==2&&scope.row.worker_id">{{scope.row.worker_name}}({{scope.row.worker_id}})</span>
            </template>
          </el-table-column>
          <el-table-column prop="member_mobile" label="手机号" width="130">
            <template #default="scope">
              <span v-if="scope.row.user_type==1">{{scope.row.member_mobile}}</span>
              <span v-if="scope.row.user_type==2">{{scope.row.worker_mobile}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="attr_name" label="类型" width="84">
            <template #default="scope">
              <el-tag size="mini" type="warning" v-if="scope.row.status==0">待结算</el-tag>
              <el-tag size="mini" type="success" v-else-if="scope.row.status==1">结算完成</el-tag>
              <el-tag size="mini" type="danger" v-else-if="scope.row.status==2">结算失败</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="attr_name" label="分佣金额" width="136">
            <template #default="scope">
              <span v-if="scope.row.amount">￥ {{scope.row.amount}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="attr_name" label="分佣金额" width="136">
            <template #default="scope">
              <span v-if="scope.row.amount">￥ {{scope.row.amount}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="attr_name" label="订单金额" width="136">
            <template #default="scope">
              <span v-if="scope.row.order_total_amount">￥ {{scope.row.order_total_amount}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="attr_name" label="扣费金额" width="136">
            <template #default="scope">
              <span v-if="scope.row.deduction_amount">￥ {{scope.row.deduction_amount}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="tip" show-overflow-tooltip label="备注" width="244"> </el-table-column>
          <el-table-column fixed="right" prop="attr_name" label="操作" width="104">
            <template #default="scope">
             <el-button :disabled="scope.row.status!=2" size="mini" type="primary" @click="reSet(scope.row)">重新结算</el-button>
            </template>
          </el-table-column>
        </el-table>


        <div style="margin-top: 10px;color: #606266;display: flex;justify-content: space-between"><span>● 订单记录</span> </div>
        <el-table border max-height="300px" :data="ruleForm.order_log" style="width: 100%;margin:10px 0 30px 0">
          <el-table-column prop="member_name" label="类型" width="67">
            <template #default="scope">
              <el-tag v-if="scope.row.worker_id" size="mini">师傅</el-tag>
              <el-tag v-if="scope.row.member_id" type="success" size="mini">用户</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="member_name" label="姓名" width="130">
            <template #default="scope">
              <span v-if="scope.row.member_id" size="mini">{{scope.row.member_name}}</span>
              <span v-if="scope.row.worker_id" type="success" size="mini">{{scope.row.worker_name}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="member_mobile" label="电话" width="117">
            <template #default="scope">
              <span v-if="scope.row.member_id" size="mini">{{scope.row.member_mobile}}</span>
              <span v-if="scope.row.worker_id" type="success" size="mini">{{scope.row.worker_mobile}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="content" label="操作内容" min-width="205"> </el-table-column>
          <el-table-column prop="create_time" label="操作时间" width="161"> </el-table-column>
        </el-table>
      </el-scrollbar>
    </el-drawer>
  </div>
</template>
<script>
// @ is an alias to /src
import * as axios from "@/api/orderApi"//本页面接口
import {get,post,addDq} from "@/api/Api"//本页面接口

export default {
  name: 'brand',
  data(){
    return{
      areaArr:[],
      group_id:'',
      login:false,
      drawer:false,
      orderdrawer:false,
      tableData:[],
      page:1,
      limit:10,
      total:0,
      keywords:'',
      area_id:null,
      r_area:[],
      status:'',
      rowData:{},
      OffertableData:[],
      SearchStatusArr:{},
      Offerpage:1,
      Offerlimit:10,
      Offertotal:0,
      Offerkeywords:'',
      region:[],
      ruleForm:{
        id:'',
        name:'',
      },
      rules:{
        name: [
          { required: true, message: '请填写名称', trigger: 'change' },
        ],
      },
        area_type:"全部",
        lnTypeList:[],
        is_show:false
    }
  },

  created() {
    this.onTable()
    this.keyupSubmit()
    
    this.onloop()
    this.ongroup()
    this.getLnTypeList()
    this.getaddDq()
    this.onLoad()
  },
  mounted(){
  this.onserch()
  },
  methods:{
      onLoad(){
          //是否鲁能账号
          var user = JSON.parse(localStorage.getItem('user'));
          if (user.id == '200927'){
              this.is_show = true;
          }
          console.log(user)
      },

    ongroup(){
      get('','/api/admin/woker_group').then((res)=>{
        this.areaArr=res.data
      }).catch((err)=>{

      })
    },
    detail(){
      // console.log('order_id',this.ruleForm.order_id)
      post({order_id:this.rowData.id},'/api/admin/order/reOrderSettle').then((res)=>{
        if(res.code==200){
          this.$message.success(res.msg)
          this.onOrder(this.rowData)
        }else {
          this.$message.error(res.msg)
        }
      }).catch((err)=>{
        this.$message.error('请求失败，请联系管理员！ ')
      })
    },
    serchOrder(row){
      post(row,'/api/admin/order/queryPayInfo').then((res)=>{
        if(res.code==200){
          this.$message.success(res.msg)
          this.onOrder(this.rowData)
        }else {
          this.$message.error(res.msg)
        }
      }).catch((err)=>{
        this.$message.error('请求失败，请联系管理员！ ')
      })
    },
    reSet(row){
      this.$confirm('重新结算!，是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        post({settle_id:row.id},'/api/admin/order/reSettlement').then((res)=>{
          if(res.code==200){
            this.$message.success(res.msg)
            this.onOrder(this.rowData)
          }else {
            this.$message.error(res.msg)
          }
        }).catch((err)=>{
          this.$message.error('请求失败，请联系管理员！ ')
        })
      }).catch(() => {
            this.$message({
              type: 'error',
              message: '已取消',
            })
          })
    },
    onloop(){
      let that=this
      setTimeout(function () {
        that.onTable()
        that.onserch()
        that.onloop()
      },60000)
    },
    keyupSubmit(){
      document.onkeydown=e=>{
        let _key=window.event.keyCode;
        if(_key===13){
          this.search()
        }
      }
    },
    onserch(){
       axios.getAdSerchData({area_id:this.area_id}).then((res)=>{
        // console.log(res)
        this.SearchStatusArr=res.data
      }).catch((err)=>{

      })
    },
    onOrderSerch(val){
      this.page=1
      this.status=val
      this.onTable()
    },
    onTable(){
      let form={
        page:this.page,
        limit:this.limit,
        keywords:this.keywords,
        status:this.status,
          area:this.area_type,
          area_id:this.area_id
      }
      axios.getAdData(form).then((res)=>{
        // console.log(res)
        this.tableData=res.data.data
        // console.log('this.tableData',this.tableData)
        this.total=res.data.total
      }).catch((err)=>{

      })
    },
    /*订单详情*/
    onOrder(row){
      this.rowData=row
      axios.getInfoData({order_id:row.id}).then((res)=>{
        this.ruleForm=res.data
      }).catch((err)=>{

      })
      this.orderdrawer=true
    },
    /*订单详情*/
    /*指派师傅*/
    Offersearch(){
      this.Offerpage=1
      this.upData(this.rowData)
    },
    OfferSizeChange(val) {
      // console.log(`每页 ${val} 条`)
      this.Offerpage=1
      this.Offerlimit=val
      this.upData(this.rowData)
    },
    OfferCurrentChange(val) {
      // console.log(`当前页: ${val}`)
      this.Offerpage=val
      this.upData(this.rowData)
    },
    upData(row){
      this.rowData=row
      axios.getOfferWorker({order_id:row.id,group_id:this.group_id,keywords:this.Offerkeywords,page:this.Offerpage,limit:this.Offerlimit}).then((res)=>{
        if(res.code==200){
          // console.log(res)
          this.OffertableData=res.data.data
          this.Offertotal=res.data.total
        }else {
          this.$message.error(res.msg)
        }
      }).catch((err)=>{

      })
      this.drawer=true
    },
    offWorker(row){
      this.$confirm('改派 ['+row.nickname+'] 师傅，是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        post({worker_id:row.id,order_id:this.rowData.id},'/api/admin/editAssignWorker').then((res)=>{
          if(res.code==200){
            this.$message.success(res.msg)
            this.search()
            this.drawer=false
          }else {
            this.$message.error(res.msg)
          }
        }).catch((err)=>{
          this.$message.error('服务器报错！！请联系管理员')
        })
      })
          .catch(() => {
            this.$message({
              type: 'error',
              message: '已取消指派',
            })
          })
    },
    onWorker(row){
      this.$confirm('指派 ['+row.nickname+'] 师傅，是否继续?', '提示',{
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
            axios.postAssignWorker({worker_id:row.id,order_id:this.rowData.id}).then((res)=>{
              if(res.code==200){
                this.$message.success(res.msg)
                this.search()
                this.drawer=false
              }else {
                this.$message.error(res.msg)
              }
            }).catch((err)=>{
              this.$message.error('服务器报错！！请联系管理员')
            })
          })
          .catch(() => {
            this.$message({
              type: 'error',
              message: '已取消指派',
            })
          })
    },
    /*指派师傅*/

    search(){
      this.page=1
      this.onTable()
      this.onserch()
    },
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`)
      this.limit=val
      this.onTable()
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`)
      this.page=val
      this.onTable()
    },
    deleData(row){
      this.$confirm('删除!!, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
          .then(() => {
            // axios.postdelProductCon({id:row.id}).then((res)=>{
            //   if(res.code==200){
            //     this.$message.success(res.msg)
            //     this.onTable()
            //     let that=this
            //     setTimeout(function () {
            //       that.drawer=false
            //     },1000)
            //   }else {
            //     this.$message.error(res.msg)
            //   }
            // }).catch((err)=>{
            //   this.$message.error('服务器报错！！请联系管理员')
            // })
          })
          .catch(() => {
            this.$message({
              type: 'error',
              message: '已取消删除',
            })
          })
    },

      //鲁能筛选类型
      getLnTypeList()
      {
          get({},'/api/admin/other/getLnTypeList').then((res)=>{
              if(res.code===200){
                  this.lnTypeList=res.data;
              }
          })
      },
      //  订单导出
      onDownload(){
            var url = process.env.VUE_APP_BASE_URL+'/api/admin/exportOrder?area='+this.area_type+"&keywords="+this.keywords
            if(this.is_show){
                url = process.env.VUE_APP_BASE_URL+'/api/admin/exportOrder?area='+this.area_type+"&keywords="+this.keywords+"&user=鲁能"
            }
          console.log(url)
          //创建一个a标签
          let link = document.createElement('a');
          link.style.display = 'none'
          link.href = url
          // link.setAttribute('download', name+ '.xls')//自定义下载的文件的名称
          document.body.appendChild(link);//添加a标签
          link.click()
          document.body.removeChild(link);//移除a标签
      },
    // 获取城市地区列表数据
    async getaddDq(){
      const {data:res} = await addDq()
      this.region = res
      this.area_id = this.region[0].area_id
      this.onTable()
    },
    clearOption(){
      this.onTable()
      this.onserch()

    }
  }
}
</script>
<style lang="less" scoped>
.onserch{
  background-color:#409eff!important;
  color: white!important;
}

</style>